import { Link } from 'react-router-dom';
import Search from '../components/Search';
export default function Navbar() {
    return(
        <header>
            <h1><Link to="/">Excuse Database</Link></h1>

            <ul>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/submit">Submit</Link></li>
            </ul>

			{/* <Search /> */}
        </header>
    )
}