

export default function Home() {

	
	return (
		<div>
      <h2>About</h2>
			<p>The Excuse Database (formerly the Excuse Rolodex) has been online since 2002. It is inspired by these Seinfeld moments:</p>
      <iframe width="280" height="157" src="https://www.youtube.com/embed/kJQDkuMzPSA?si=cnfcZoLbPmn0aE-B" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
			<h3>Tech</h3>
			<p>The Excuse Database started with a PHP/MySQL website using CodeIgniter and hosted on Dreamhost. After that it was moved to a React app using Firebase as the backend, hosting on Digital Ocean. Then I added a Node backend to connect the Firebase, which I hosted on Vercel. Next I went back to MySQL, keeping Firebase for authentication, and hosting the backend on Digital Ocean.</p>
			<p>Currently the app runs on React, React Native, Node, Firebase; the website hosts on Digital Ocean and the backend is on Vercel.</p>
</div>
	)
}