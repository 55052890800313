import { useState, useEffect } from 'react';
import axios from 'axios';
import fontdata from '../constants/fonts'
import colors from '../constants/colors'
import situationdata from '../constants/situations';

import fontColorContrast from 'font-color-contrast'

let api = 'http://localhost:7654/';
api = 'https://excuse-database-firebase.vercel.app/'

function shuffle(array) {
	let currentIndex = array.length, randomIndex;

	// While there remain elements to shuffle.
	while (currentIndex > 0) {

		// Pick a remaining element.
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		// And swap it with the current element.
		[array[currentIndex], array[randomIndex]] = [
			array[randomIndex], array[currentIndex]];
	}

	return array;
}

function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

export default function Home() {

	const [loading, setLoading] = useState(true)
  const [excuses, setExcuses] = useState(null)
  const [reload, setReload] = useState(false)
  const [colors, setColors] = useState([]);
  const [fonts, setFonts] = useState([])
  const [situation, setSituation] = useState('')
  const [situations, setSituations] = useState(shuffle(situationdata));
  const [situationIndex, setSituationIndex] = useState(-1);
  const [showVotes, setShowVotes] = useState(false)
	const generateRandomColors = () => {
    const newColors = Array.from({ length: 4 }, () => getRandomColor());
    setColors(newColors);
  };
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const updateFonts = () => {
    let newFonts = fontdata;
    shuffle(newFonts);
    setFonts([newFonts[0], newFonts[1], newFonts[2], newFonts[3]])
  }

  const updateSituationIndex = () => {
    if (situationIndex + 1 >= situations.length) {
      setSituationIndex(0);
    }
    else {
      setSituationIndex(old => old + 1)
    }
  }
  useEffect(() => {
    axios.get(api + "getFour")
      .then(results => {
        setExcuses(results.data.excuses);
        generateRandomColors();
        updateSituationIndex();
        updateFonts();
        setSituation(situations[getRandomInt(0, situations.length)])
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
      });
  }, []); // Remove 'reload' from dependencies

  function vote(val) {
    // Move the logic from useEffect here directly
        setShowVotes(true);
        for (let i = 0; i < 4; i++)
        {
          if (i === val) {

            axios.post(api + "updateItem", {
              path: 'excuses/' + excuses[i].eid,
              item: {
                saved: excuses[i].saved + 1
              }
            })
          }
          else {

            axios.post(api + "updateItem", {
              path: 'excuses/' + excuses[i].eid,
              item: {
                saved: excuses[i].booed + 1
              }
            })
          }
        }
        setTimeout(() => {
        axios.get(api + "getFour")
        .then(results => {
          setExcuses(results.data.excuses);
          generateRandomColors();
          updateFonts();
          setSituation(situations[getRandomInt(0, situations.length)])
          setShowVotes(false);
        
      })
      .catch(err => {
        console.log(err);
      })
    }, 1000)
  }

  const Button = ({ index }) => {

    const percent = (excuses[index].saved / (excuses[index].saved + excuses[index].booed) * 100).toFixed(0)

    return (
      <div style={{ backgroundColor: colors[index] }} onClick={() => vote(excuses[index])}>
        <p className="excuse" style={{ color: fontColorContrast(colors[index]), fontFamily: fonts[index] }}>
          {showVotes ? percent + "%" : excuses[index].text}
        </p>
      </div>
    )
  }

	return (
		<div>
				{
					loading ?
						<p>Loading</p>
						:
						<>
							<div className="situation">
								<p>{situation}</p>
							</div>
							<div className="buttons-container">
              <Button index={0} />
              <Button index={1} />
							 
            <Button index={2} />
              <Button index={3} />
							</div>
							</>
}
</div>
	)
}