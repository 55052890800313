import { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuth, signInWithPopup,signOut,createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";


let api = 'http://localhost:7654/';
api = 'https://excuse-database-firebase.vercel.app/'
export default function Home() {

	const auth = getAuth()
	const [firebaseUser, setFirebaseUser] = useState(null)
	const [user, setUser] = useState(null)

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [logEmail, setLogEmail] = useState("");
  const [logPassword, setLogPassword] = useState("");
  const [excuses, setExcuses] = useState(null)
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [reload, setReload] = useState(false)
  const [newExcuse, setNewExcuse] = useState('')
	
useEffect(() => {
  onAuthStateChanged(auth, (userCred) => {
    if (userCred) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      setUser(userCred);
      // ...
    } else {
      // User is signed out
      // ...
			setUser(null)
    }
  });
}, [])

	const signIn = () => {
		const provider = new GoogleAuthProvider();
		signInWithPopup(auth, provider)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    // The signed-in user info.
		setUser(result.user)
		setLoading(false)
    // IdP data available using getAdditionalUserInfo(result)
    // ...
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // ...
  });
	}

	const sortByRatio = (a, b) => {
    const ratioA = a.saved / (a.saved + a.booed);
    const ratioB = b.saved / (b.saved + b.booed);

    return ratioB - ratioA; // Sort in descending order
  };
  useEffect(() => {
    if (user) {
      axios.post(api + "getUserExcuses", {
        uid: user.uid
      })
        .then(results => {
          const sortedData = results.data.sort(sortByRatio).slice(0, 20);
          setExcuses(sortedData)
          console.log(sortedData)
        })
        .catch(err => {
          console.log(err)
        })
					setLoading(false)
    }
  }, [reload, user])

  const submitExcuse = () => {
    if (!newExcuse) {
      alert("Please enter an excuse");
      return;
    }
    axios.post(api + 'addItem', {
      path: 'excuses',
      item: {
        text: newExcuse,
        uid: user.uid,
        saved: 0,
        booed: 0,
        dateSubmitted: Date.now(),
        approved: "no"
      }
    })
    setNewExcuse('')
    setReload(old => !old)
  }

  function handleCreate(e) {
		e.preventDefault();
    if (email && password) {
      // Create a new user with the provided email and password
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // User account created successfully
          console.log(userCredential.user);
        })
        .catch((error) => {
          // Handle errors during account creation
          alert(error.message);
        });
    } else {
      // Alert the user if fields are missing
      alert("Please fill out both fields.");
    }
  }

  // Function to handle user login
  function handleLogin(e) {
		e.preventDefault();
    if (logEmail && logPassword) {
      // Sign in with the provided email and password
      signInWithEmailAndPassword(auth, logEmail, logPassword)
        .then((userCredential) => {
          // User successfully signed in
          setUser(userCredential.user);
        })
        .catch((error) => {
          // Handle errors during login
          alert(error.message);
        });
    }
  }
	const logout = () => {
    signOut(auth);
		setUser(null)
	}

	return (
		<div className="submit">
			{
				loading ?
					<p>Loading</p>
					:
					user ? 
					<div>
					<h2>Submit</h2>
					<textarea value={newExcuse} onChange={e => setNewExcuse(e.target.value)} placeholder="Your excuse" />
					<button onClick={submitExcuse}>Submit</button>
					<h3>Your excuses</h3>
					<ul className="excuses">
						{
							excuses && 
							excuses.map((item) => {
								return(
									<li key={item.id}>
										<span>{item.text}</span>
										<span>{!isNaN(item.saved / (item.saved + item.booed) * 100) ? (item.saved / (item.saved + item.booed) * 100).toFixed(0) : 0}%</span>
									</li>
								)
							})
						}
					</ul>
					<button onClick={logout}>Logout</button>
					</div>
					:
					<>
					<h2>Login to submit</h2>
					<button onClick={signIn}>Login with Google</button>
					<form onSubmit={(e) => handleLogin(e)}>
						<label>Email</label>
						<input type="text" onChange={e => setLogEmail(e.target.value)} value={logEmail} />
						<label>Password</label>
						<input type="text" onChange={e => setLogPassword(e.target.value)} value={logPassword} />
						<button onClick={(e) => handleLogin(e)}>Login</button>
					</form>
					<h2>Sign up</h2>
					<form onSubmit={(e) => handleCreate(e)}>
						<label>Email</label>
						<input type="text" onChange={e => setEmail(e.target.value)} value={email} />
						<label>Password</label>
						<input type="text" onChange={e => setPassword(e.target.value)} value={password} />
						<button onClick={(e) => handleCreate(e)}>Sign up</button>
					</form>
					</>
			}
		</div>
	)
}