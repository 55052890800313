import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Submit from './pages/Submit';
import About from './pages/About';
import Navbar from './components/Navbar';
import './constants/firebase'
function App() {

  return (
    <BrowserRouter>
      <div className="container">
        <Navbar />
       
      <Routes>
      
        <Route element={<Home />} path="/" />
        <Route element={<Submit />} path="/submit" />
        <Route element={<About />} path="/about" />
      
      </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;