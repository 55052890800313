// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD4KUhybPzAt7qvhzHaP0YtGi4NrEDWpcY",
  authDomain: "excuse-rolodex-339b7.firebaseapp.com",
  databaseURL: "https://excuse-rolodex-339b7.firebaseio.com",
  projectId: "excuse-rolodex-339b7",
  storageBucket: "excuse-rolodex-339b7.appspot.com",
  messagingSenderId: "897610669550",
  appId: "1:897610669550:web:e5cee9a3e854ec9b1a02ff",
  measurementId: "G-2946R2M34K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);