const situations = [
"Can you help me paint my house?",
  "Do you want to go hiking this weekend?",
  "Would you like to join me for a game of tennis?",
  "Can you teach me how to bake a cake?",
  "Do you want to go to the movies tonight?",
  "Would you like to go to the beach with me?",
  "Can you recommend a good restaurant in town?",
  "Do you want to come over for a board game night?",
  "Would you like to try a new workout class with me?",
  "Can you help me plan a surprise party?",
  "Do you want to go shopping together?",
  "Would you like to volunteer at a local charity with me?",
  "Can you show me how to change a tire?",
  "Do you want to take a cooking class together?",
  "Would you like to come to a music festival with me?",
  "Can you help me organize my closet?",
  "Do you want to go to an art exhibit with me?",
  "Would you like to attend a wine tasting event with me?",
  "Can you teach me how to knit?",
  "Do you want to go on a road trip this summer?",
  "Would you like to come to my book club meeting?",
  "Can you help me plan a weekend getaway?",
  "Do you want to go to a comedy show with me?",
  "Would you like to join me for a yoga class?",
  "Can you help me set up a garden?",
  "Do you want to go to a music concert with me?",
  "Would you like to try a new restaurant with me?",
  "Can you teach me how to play chess?",
  "Do you want to come over for a barbecue party?",
  "Would you like to go to a dance class with me?",
  "Can you help me clean my garage?",
  "Do you want to go to a sports game with me?",
  "Would you like to come to a poetry reading event with me?",
  "Can you show me how to sew a dress?",
  "Do you want to take a photography class together?",
  "Would you like to go to a theme park with me?",
  "Can you help me prepare for a job interview?",
  "Do you want to go to a museum with me?",
  "Would you like to attend a beer tasting event with me?",
  "Can you teach me how to play guitar?",
  "Do you want to come over for a game night?",
  "Would you like to join me for a meditation session?",
  "Can you help me declutter my home?",
  "Do you want to go to a fashion show with me?",
  "Would you like to try a new cocktail bar with me?",
  "Can you show me how to do a DIY project?",
  "Do you want to take a language class together?",
  "Would you like to go to a food festival with me?",
  "Can you help me design my garden?",
  "Do you want to go to a theater show with me?",
  "Would you like to come to a film screening event with me?",
  "Can you teach me how to ride a bike?",
  "Do you want to go to a concert in the park with me?",
  "Would you like to join me for a pottery class?",
  "Can you help me assemble a piece of furniture?",
  "Do you want to go to a charity event with me?",
  "Would you like to attend a cocktail-making class with me?",
  "Can you show me how to make homemade soap?",
  "Can you teach my cat to breakdance?",
  "Will you find a unicorn and bring it to my backyard?",
  "Is it possible to translate Shakespearean sonnets into Klingon?",
  "Could you invent a teleportation device for my morning commute?",
  "Can you bake cookies on the sun?",
  "How about teaching my goldfish to play the piano?",
  "Can you create a time machine for the sole purpose of attending a dinosaur tea party?",
  "Would you design a hat for my pet rock?",
  "Could you develop a universal remote control for reality?",
  "Is it possible to train a group of squirrels to perform a synchronized swimming routine?",
  "How about building a chocolate fountain that dispenses hot cocoa?",
  "Can you invent a language that only dogs can understand?",
  "Is it possible to create a robot that tells jokes and laughs at them?",
  "Could you brew a potion that turns me into a wizard on weekends?",
  "Can you teach my computer to appreciate fine art?",
  "How about constructing a treehouse for my imaginary friend?",
  "Could you develop an app that predicts the next color of my neighbor's car?",
  "Is it possible to invent self-refilling ice cream cones?",
  "Can you build a bridge made entirely of marshmallows?",
  "How about teaching a parrot to recite Shakespearean insults?",
  "Could you create a hat that generates Wi-Fi signals?",
  "Is it possible to design a pillow that plays soothing whale sounds when hugged?",
  "Can you invent a pen that writes with rainbow-colored ink?",
  "How about training a group of penguins to be professional dancers?",
  "Could you develop a keyboard that types in interpretive dance moves?",
  "Can you bake a cake in the shape of the Eiffel Tower made entirely of spaghetti?",
  "Is it possible to create a perfume that smells like a rainbow after a spring rain?",
  "Can you invent a robot that gives motivational speeches to plants?",
  "How about teaching my toaster to compose symphonies?",
  "Could you build a flying saucer for my pet hamster?",
  "Can you design a hat that doubles as a snack dispenser?",
  "Is it possible to invent a machine that translates baby talk into adult language?",
  "Can you train a team of ants to perform circus tricks?",
  "How about creating a computer program that writes love letters to my cat?",
  "Could you build a rocket-powered skateboard for commuting to work?",
  "Can you invent a device that translates dog barks into human language?",
  "Is it possible to teach my refrigerator to order groceries online?",
  "Can you design a watch that tells time in cat years?",
  "How about creating a robot that gives massages and tells bedtime stories?",
  "Could you invent a hat that changes color based on my mood?",
  "Can you train a group of turtles to participate in a marathon?",
  "Is it possible to develop a bubblegum that never loses its flavor?",
  "Can you build a gadget that finds missing socks in the laundry?",
  "How about teaching my blender to dance salsa while making smoothies?",
  "Could you invent a plant that grows chocolate bars instead of flowers?",
  "Can you design a hat with built-in GPS for finding lost keys?"
]
export default situations;